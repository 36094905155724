





























import Vue from 'vue';
import AppMain from '@/components/AppMain.vue';
import AppForm from '@/components/AppForm.vue';
import TRUCK from '@/graphql/queries/Truck.graphql';
import TRUCK_CREATE from '@/graphql/mutations/TruckCreate.graphql';
import TRUCK_UPDATE from '@/graphql/mutations/TruckUpdate.graphql';
import { required } from 'vuelidate/lib/validators';
import validationMessages from '@/utils/validationMessages';
import { CODES } from '@/utils/constants';
import {
  Truck,
  TruckCreateInput,
  TruckCreateMutationVariables,
  TruckQueryVariables,
  TruckUpdateMutationVariables,
} from '@/types/schema';
import { GraphQLError } from 'graphql';
import { ApolloQueryResult } from 'apollo-client';

export default Vue.extend({
  name: 'AdminTrucksAdd',
  components: {
    AppMain,
    AppForm,
  },
  data() {
    return {
      loading: false,
      errors: [] as GraphQLError[],
      form: {
        name: '',
      } as TruckCreateInput,
      truck: {} as Truck,
    };
  },
  validations() {
    return {
      form: {
        name: { required },
      },
    };
  },
  methods: {
    validationMessages,
    fillForm(truck: Truck) {
      if (!truck) return;
      this.form = {
        name: truck.name || '',
      };
    },
    async saveTruck() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      try {
        this.loading = true;
        this.errors = [];
        if (this.$route.params.id) await this.updateTruck();
        else await this.addTruck();
        this.$router.replace({ name: 'admin-trucks' });
        this.$notify({
          text: 'Truck saved!',
          type: 'success',
          duration: 6000,
        });
      } catch ({ graphQLErrors }) {
        this.errors = graphQLErrors;
      } finally {
        this.loading = false;
      }
    },
    addTruck() {
      return this.$apollo.mutate({
        mutation: TRUCK_CREATE,
        variables: {
          input: {
            name: this.form.name,
          },
        } as TruckCreateMutationVariables,
      });
    },
    updateTruck() {
      return this.$apollo.mutate({
        mutation: TRUCK_UPDATE,
        variables: {
          input: {
            id: this.truck.id,
            name: this.form.name,
          },
        } as TruckUpdateMutationVariables,
      });
    },
  },
  apollo: {
    truck: {
      query: TRUCK,
      variables(): TruckQueryVariables {
        return {
          id: this.$route.params.id,
        };
      },
      result({ data }: ApolloQueryResult<{ truck: Truck }>) {
        if (data) this.fillForm(data.truck);
      },
      error({ graphQLErrors }) {
        const [gqlError] = graphQLErrors;
        if (gqlError?.extensions?.code === CODES.NOT_FOUND) {
          this.$router.replace({ name: 'admin-trucks' });
        }
      },
      skip(): boolean {
        return !this.$route.params.id;
      },
    },
  },
});
